<template>
  <div class="page" :class="{'ratio': devicePixelRatio === 2}">
    <div class="block bar">
      <div class="bar_content">
        <div class="bar_value">
          <div class="title">OUR VISION</div>
          <div class="text">BERYL SPRING GLOBAL LTD is building an excellent online inclusive financial platform for emerging markets.</div>
          <div class="hint">We are supervised by local financial institutions in Nigeria. We have established a complete risk management system and user data protection mechanism. We uphold the principles of fairness and integrity, ensure that all products are legal and compliant, and protect the safety of user funds.</div>
        </div>
      </div>
      <div class="bar_img">
        <img v-if="devicePixelRatio == 2" src="@/assets/img/our_banner@2x.png">
        <img v-else src="@/assets/img/our_banner.png">
      </div>
    </div>

    <div class="block prompt">
      <div class="block_box">
        <div class="title">WHAT WE ARE</div>
        <div class="line"></div>
        <div class="text">We built a smart, credit-led digital lending platform: making people’s lives easier when they need a loan to fund their business or for personal reasons, without collateral. Most Nigerian groups do not have access to loan services from traditional banks, so we provide inclusive lending to their various needs to help them thrive.</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'WhoWeAre',
  data() {
    return {
      devicePixelRatio: window.devicePixelRatio,
    };
  },
};
</script>

<style lang="scss" scoped>
@mixin pixel-ratio() {
  @media screen and (-webkit-min-device-pixel-ratio: 1),
  screen and (min--moz-device-pixel-ratio: 1) {
    @import '../assets/scss/sprite/whoWeAre.scss';
    .list {
      .list_content {
        .list_content_item {
          &:nth-child(1) .img {
            @include sprite($our_icon_1);
          }
          &:nth-child(2) .img {
            @include sprite($our_icon_2);
          }
          &:nth-child(3) .img {
            @include sprite($our_icon_3);
          }
          &:nth-child(4) .img {
            @include sprite($our_icon_4);
          }
          &:nth-child(5) .img {
            @include sprite($our_icon_5);
          }
          &:nth-child(6) .img {
            @include sprite($our_icon_6);
          }
          &:nth-child(7) .img {
            @include sprite($our_icon_7);
          }
          &:nth-child(8) .img {
            @include sprite($our_icon_8);
          }
          &:nth-child(9) .img {
            @include sprite($our_icon_9);
          }
          &:nth-child(10) .img {
            @include sprite($our_icon_10);
          }
        }
      }
    }
  }
  @media screen and (-webkit-min-device-pixel-ratio: 2),
  screen and (min--moz-device-pixel-ratio: 2) {
    @import '../assets/scss/sprite/whoWeAre@2x.scss';
    .list {
      .list_content {
        .list_content_item {
          &:nth-child(1) .img {
            @include sprite($our_icon_1-2x);
          }
          &:nth-child(2) .img {
            @include sprite($our_icon_2-2x);
          }
          &:nth-child(3) .img {
            @include sprite($our_icon_3-2x);
          }
          &:nth-child(4) .img {
            @include sprite($our_icon_4-2x);
          }
          &:nth-child(5) .img {
            @include sprite($our_icon_5-2x);
          }
          &:nth-child(6) .img {
            @include sprite($our_icon_6-2x);
          }
          &:nth-child(7) .img {
            @include sprite($our_icon_7-2x);
          }
          &:nth-child(8) .img {
            @include sprite($our_icon_8-2x);
          }
          &:nth-child(9) .img {
            @include sprite($our_icon_9-2x);
          }
          &:nth-child(10) .img {
            @include sprite($our_icon_10-2x);
          }
        }
      }
    }
  }
}

.page {
  @include pixel-ratio();
  .block {
    text-align: left;
    .title {
      font-size: 32px;
      font-weight: 700;
      color: #333333;
    }
    .text {
      font-size: 14px;
      color: #333333;
      padding: 10px 0px;
    }
    .hint {
     font-size: 13px;
     color: #333333;
     padding: 10px 0px;
    }
    .line {
      height: 0.5px;
      background: #CCCCCC;
    }
    .el-button {
      margin-top: 30px;
    }
    &_box {
      max-width: 1200px;
      margin: 0 auto;
    }
    &.bar {
      position: relative;
      background: white;
      .bar_img {
        width: 100%;
        img {
          width: 100%;
        }
      }
      .bar_content {
        position: absolute;
        z-index: 2;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        .bar_value {
          position: relative;
          top: 50%;
          transform: translateY(-50%);
          padding: 0px 10px;
          max-width: 1200px;
          margin: 0 auto;
          .hint {
            max-width: 500px;
          }
        }
      }
    }
    &.prompt {
      margin-top: 40px;
      .block_box {
        padding: 10px;
      }
    }
    &.list {
      margin-top: 40px;
      .block_box {
        padding: 10px;
      }
      .list_content {
        margin-top: 30px;
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        &_item {
          background: rgba(103, 45, 158, 0.03);
          border-radius: 4px;
          display: inline-block;
          margin-bottom: 16px;
          width: 30%;
          .img {
            width: 35px !important;
            height: 35px !important;
            border-radius: 35px;
            background: rgba(103, 45, 158, 0.1);
            margin-top: 35px;
            margin: 35px 20px 10px 20px;
          }
          .text {
            padding: 0px 20px;
          }
          .hint {
            margin-top: 15px;
            margin-bottom: 30px;
            color: #333333;
            padding: 0px 20px;
          }
        }
      }
    }
    &.join {
      margin-top: 33px;
      margin-bottom: 20px;
      .block_box {
        display: flex;
      }
      .join_content {
        padding: 10px;
        padding-top: 55px;
        margin-bottom: 20px;
        flex: 1;
      }
      .join_img {
        max-width: 396px;
        font-size: 0px;
        img {
          width: 100%;
        }
      }
    }
  }

  @media screen and (max-width: 800px) {
    .block {
      text-align: center;
      .block_box {
        display: inline-block;
      }
      &.bar {
        min-height: 600px;
        .bar_img {
          position: absolute;
          top: 150px;
          bottom: 0px;
          left: 0px;
          right: 0px;
          &::before {
            content: "";
            position: absolute;
            z-index: 1;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-image: linear-gradient(0deg,rgba(0,0,0,0.00)0%,#ffffff 100%);
          }
          img {
            height: 100%;
            width: auto;
            position: absolute;
            right: 0;
            transform: translateX(8%);
          }
        }
        .title {
          font-size: 26px;
        }
        .el-button {
          position: absolute;
          bottom: 28px;
          left: 50%;
          transform: translateX(-50%);
        }
        .bar_content {
          padding-top: 30px;
          padding-bottom: 20px;
          .bar_value {
            position: initial;
            top: 10px;
            transform: none;
          }
        }
      }
      &.list {
        .block_box {
          padding: 10px 0px;
        }
        .list_content {
          .list_content_item {
            width: 100%;
            text-align: left;
            margin-bottom: 0px;
            &:nth-child(2n + 2) {
              background-color: white;
            }
          }
        }
      }
      &.join {
        .block_box {
          display: block;
        }
        .join_content {
          padding-top: 0px;
        }
        .join_img {
          margin: 0 auto;
        }
      }
    }
  }
}
</style>
